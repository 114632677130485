var exports = {};
!function (t, e) {
  {
    if (!t.document) throw new Error("HC-Sticky requires a browser to run.");
    exports = e(t);
  }
}("undefined" != typeof window ? window : exports, function (V) {
  var i,
      n,
      Q = V.document,
      U = {
    top: 0,
    bottom: 0,
    bottomEnd: 0,
    innerTop: 0,
    innerSticker: null,
    stickyClass: "sticky",
    stickTo: null,
    followScroll: !0,
    responsive: null,
    mobileFirst: !1,
    onStart: null,
    onStop: null,
    onBeforeResize: null,
    onResize: null,
    resizeDebounce: 100,
    disable: !1
  },
      Y = function (t, e, o) {
    console.warn("%cHC Sticky:%c " + o + "%c '" + t + "'%c is now deprecated and will be removed. Use%c '" + e + "'%c instead.", "color: #fa253b", "color: default", "color: #5595c6", "color: default", "color: #5595c6", "color: default");
  },
      $ = function (n, f) {
    var o = this;
    if (f = f || {}, !(n = "string" == typeof n ? Q.querySelector(n) : n)) return !1;
    f.queries && Y("queries", "responsive", "option"), f.queryFlow && Y("queryFlow", "mobileFirst", "option");
    var p = {},
        u = $.Helpers,
        s = n.parentNode;
    "static" === u.getStyle(s, "position") && (s.style.position = "relative");

    function d(t) {
      u.isEmptyObject(t = t || {}) && !u.isEmptyObject(p) || (p = Object.assign({}, U, p, t));
    }

    function t() {
      return p.disable;
    }

    function e() {
      var t,
          e = p.responsive || p.queries;

      if (e) {
        var o = V.innerWidth;
        if (t = f, (p = Object.assign({}, U, t || {})).mobileFirst) for (var i in e) i <= o && !u.isEmptyObject(e[i]) && d(e[i]);else {
          var n,
              s = [];

          for (n in e) {
            var r = {};
            r[n] = e[n], s.push(r);
          }

          for (var l = s.length - 1; 0 <= l; l--) {
            var a = s[l],
                c = Object.keys(a)[0];
            o <= c && !u.isEmptyObject(a[c]) && d(a[c]);
          }
        }
      }
    }

    function i() {
      var t, e, o, i;
      I.css = (t = n, e = u.getCascadedStyle(t), o = u.getStyle(t), i = {
        height: t.offsetHeight + "px",
        left: e.left,
        right: e.right,
        top: e.top,
        bottom: e.bottom,
        position: o.position,
        display: o.display,
        verticalAlign: o.verticalAlign,
        boxSizing: o.boxSizing,
        marginLeft: e.marginLeft,
        marginRight: e.marginRight,
        marginTop: e.marginTop,
        marginBottom: e.marginBottom,
        paddingLeft: e.paddingLeft,
        paddingRight: e.paddingRight
      }, e.float && (i.float = e.float || "none"), e.cssFloat && (i.cssFloat = e.cssFloat || "none"), o.MozBoxSizing && (i.MozBoxSizing = o.MozBoxSizing), i.width = "auto" !== e.width ? e.width : "border-box" === i.boxSizing || "border-box" === i.MozBoxSizing ? t.offsetWidth + "px" : o.width, i), P.init(), y = !(!p.stickTo || !("document" === p.stickTo || p.stickTo.nodeType && 9 === p.stickTo.nodeType || "object" == typeof p.stickTo && p.stickTo instanceof ("undefined" != typeof HTMLDocument ? HTMLDocument : Document))), h = p.stickTo ? y ? Q : u.getElement(p.stickTo) : s, z = (R = function () {
        var t = n.offsetHeight + (parseInt(I.css.marginTop) || 0) + (parseInt(I.css.marginBottom) || 0),
            e = (z || 0) - t;
        return -1 <= e && e <= 1 ? z : t;
      })(), v = (H = function () {
        return y ? Math.max(Q.documentElement.clientHeight, Q.body.scrollHeight, Q.documentElement.scrollHeight, Q.body.offsetHeight, Q.documentElement.offsetHeight) : h.offsetHeight;
      })(), S = y ? 0 : u.offset(h).top, w = p.stickTo ? y ? 0 : u.offset(s).top : S, E = V.innerHeight, N = n.offsetTop - (parseInt(I.css.marginTop) || 0), b = u.getElement(p.innerSticker), L = isNaN(p.top) && -1 < p.top.indexOf("%") ? parseFloat(p.top) / 100 * E : p.top, k = isNaN(p.bottom) && -1 < p.bottom.indexOf("%") ? parseFloat(p.bottom) / 100 * E : p.bottom, x = b ? b.offsetTop : p.innerTop || 0, T = isNaN(p.bottomEnd) && -1 < p.bottomEnd.indexOf("%") ? parseFloat(p.bottomEnd) / 100 * E : p.bottomEnd, j = S - L + x + N;
    }

    function r() {
      z = R(), v = H(), O = S + v - L - T, C = E < z;
      var t,
          e = V.pageYOffset || Q.documentElement.scrollTop,
          o = u.offset(n).top,
          i = o - e;
      B = e < F ? "up" : "down", A = e - F, j < (F = e) ? O + L + (C ? k : 0) - (p.followScroll && C ? 0 : L) <= e + z - x - (E - (j - x) < z - x && p.followScroll && 0 < (t = z - E - x) ? t : 0) ? I.release({
        position: "absolute",
        bottom: w + s.offsetHeight - O - L
      }) : C && p.followScroll ? "down" == B ? i + z + k <= E + 0.9 ? I.stick({
        bottom: k
      }) : "fixed" === I.position && I.release({
        position: "absolute",
        top: o - L - j - A + x
      }) : Math.ceil(i + x) < 0 && "fixed" === I.position ? I.release({
        position: "absolute",
        top: o - L - j + x - A
      }) : e + L - x <= o && I.stick({
        top: L - x
      }) : I.stick({
        top: L - x
      }) : I.release({
        stop: !0
      });
    }

    function l() {
      M && (V.removeEventListener("scroll", r, u.supportsPassive), M = !1);
    }

    function a() {
      null !== n.offsetParent && "none" !== u.getStyle(n, "display") ? (i(), v < z ? l() : (r(), M || (V.addEventListener("scroll", r, u.supportsPassive), M = !0))) : l();
    }

    function c() {
      n.style.position = "", n.style.left = "", n.style.top = "", n.style.bottom = "", n.style.width = "", n.classList ? n.classList.remove(p.stickyClass) : n.className = n.className.replace(new RegExp("(^|\\b)" + p.stickyClass.split(" ").join("|") + "(\\b|$)", "gi"), " "), I.css = {}, !(I.position = null) === P.isAttached && P.detach();
    }

    function g() {
      c(), e(), (t() ? l : a)();
    }

    function m() {
      q && (V.removeEventListener("resize", W, u.supportsPassive), q = !1), l();
    }

    var y,
        h,
        b,
        v,
        S,
        w,
        E,
        L,
        k,
        x,
        T,
        j,
        O,
        C,
        z,
        N,
        H,
        R,
        A,
        B,
        I = {
      css: {},
      position: null,
      stick: function (t) {
        t = t || {}, u.hasClass(n, p.stickyClass) || (!1 === P.isAttached && P.attach(), I.position = "fixed", n.style.position = "fixed", n.style.left = P.offsetLeft + "px", n.style.width = P.width, void 0 === t.bottom ? n.style.bottom = "auto" : n.style.bottom = t.bottom + "px", void 0 === t.top ? n.style.top = "auto" : n.style.top = t.top + "px", n.classList ? n.classList.add(p.stickyClass) : n.className += " " + p.stickyClass, p.onStart && p.onStart.call(n, Object.assign({}, p)));
      },
      release: function (t) {
        var e;
        (t = t || {}).stop = t.stop || !1, !0 !== t.stop && "fixed" !== I.position && null !== I.position && (void 0 === t.top && void 0 === t.bottom || void 0 !== t.top && (parseInt(u.getStyle(n, "top")) || 0) === t.top || void 0 !== t.bottom && (parseInt(u.getStyle(n, "bottom")) || 0) === t.bottom) || (!0 === t.stop ? !0 === P.isAttached && P.detach() : !1 === P.isAttached && P.attach(), e = t.position || I.css.position, I.position = e, n.style.position = e, n.style.left = !0 === t.stop ? I.css.left : P.positionLeft + "px", n.style.width = ("absolute" !== e ? I.css : P).width, void 0 === t.bottom ? n.style.bottom = !0 === t.stop ? "" : "auto" : n.style.bottom = t.bottom + "px", void 0 === t.top ? n.style.top = !0 === t.stop ? "" : "auto" : n.style.top = t.top + "px", n.classList ? n.classList.remove(p.stickyClass) : n.className = n.className.replace(new RegExp("(^|\\b)" + p.stickyClass.split(" ").join("|") + "(\\b|$)", "gi"), " "), p.onStop && p.onStop.call(n, Object.assign({}, p)));
      }
    },
        P = {
      el: Q.createElement("div"),
      offsetLeft: null,
      positionLeft: null,
      width: null,
      isAttached: !1,
      init: function () {
        for (var t in P.el.className = "sticky-spacer", I.css) P.el.style[t] = I.css[t];

        P.el.style["z-index"] = "-1";
        var e = u.getStyle(n);
        P.offsetLeft = u.offset(n).left - (parseInt(e.marginLeft) || 0), P.positionLeft = u.position(n).left, P.width = u.getStyle(n, "width");
      },
      attach: function () {
        s.insertBefore(P.el, n), P.isAttached = !0;
      },
      detach: function () {
        P.el = s.removeChild(P.el), P.isAttached = !1;
      }
    },
        F = V.pageYOffset || Q.documentElement.scrollTop,
        M = !1,
        q = !1,
        D = function () {
      p.onBeforeResize && p.onBeforeResize.call(n, Object.assign({}, p)), g(), p.onResize && p.onResize.call(n, Object.assign({}, p));
    },
        W = p.resizeDebounce ? u.debounce(D, p.resizeDebounce) : D,
        D = function () {
      q || (V.addEventListener("resize", W, u.supportsPassive), q = !0), e(), (t() ? l : a)();
    };

    this.options = function (t) {
      return t ? p[t] : Object.assign({}, p);
    }, this.refresh = g, this.update = function (t) {
      d(t), f = Object.assign({}, f, t || {}), g();
    }, this.attach = D, this.detach = m, this.destroy = function () {
      m(), c();
    }, this.triggerMethod = function (t, e) {
      "function" == typeof o[t] && o[t](e);
    }, this.reinit = function () {
      Y("reinit", "refresh", "method"), g();
    }, d(f), D(), V.addEventListener("load", g);
  };

  return void 0 !== V.jQuery && (i = V.jQuery, n = "hcSticky", i.fn.extend({
    hcSticky: function (e, o) {
      return this.length ? "options" === e ? i.data(this.get(0), n).options() : this.each(function () {
        var t = i.data(this, n);
        t ? t.triggerMethod(e, o) : (t = new $(this, e), i.data(this, n, t));
      }) : this;
    }
  })), V.hcSticky = V.hcSticky || $, $;
}), function (a) {
  var t = a.hcSticky,
      c = a.document;
  "function" != typeof Object.assign && Object.defineProperty(Object, "assign", {
    value: function (t, e) {
      if (null == t) throw new TypeError("Cannot convert undefined or null to object");

      for (var o = Object(t), i = 1; i < arguments.length; i++) {
        var n = arguments[i];
        if (null != n) for (var s in n) Object.prototype.hasOwnProperty.call(n, s) && (o[s] = n[s]);
      }

      return o;
    },
    writable: !0,
    configurable: !0
  }), Array.prototype.forEach || (Array.prototype.forEach = function (t) {
    var e, o;
    if (null == this) throw new TypeError("this is null or not defined");
    var i,
        n = Object(this),
        s = n.length >>> 0;
    if ("function" != typeof t) throw new TypeError(t + " is not a function");

    for (1 < arguments.length && (e = arguments[1]), o = 0; o < s;) o in n && (i = n[o], t.call(e, i, o, n)), o++;
  });
  var e = !1;

  try {
    var o = Object.defineProperty({}, "passive", {
      get: function () {
        e = {
          passive: !1
        };
      }
    });
    a.addEventListener("testPassive", null, o), a.removeEventListener("testPassive", null, o);
  } catch (t) {}

  function n(t, e) {
    return a.getComputedStyle ? e ? c.defaultView.getComputedStyle(t, null).getPropertyValue(e) : c.defaultView.getComputedStyle(t, null) : t.currentStyle ? e ? t.currentStyle[e.replace(/-\w/g, function (t) {
      return t.toUpperCase().replace("-", "");
    })] : t.currentStyle : void 0;
  }

  function s(t) {
    var e = t.getBoundingClientRect(),
        o = a.pageYOffset || c.documentElement.scrollTop,
        t = a.pageXOffset || c.documentElement.scrollLeft;
    return {
      top: e.top + o,
      left: e.left + t
    };
  }

  t.Helpers = {
    supportsPassive: e,
    isEmptyObject: function (t) {
      for (var e in t) return !1;

      return !0;
    },
    debounce: function (i, n, s) {
      var r;
      return function () {
        var t = this,
            e = arguments,
            o = s && !r;
        clearTimeout(r), r = setTimeout(function () {
          r = null, s || i.apply(t, e);
        }, n), o && i.apply(t, e);
      };
    },
    hasClass: function (t, e) {
      return t.classList ? t.classList.contains(e) : new RegExp("(^| )" + e + "( |$)", "gi").test(t.className);
    },
    offset: s,
    position: function (t) {
      var e = t.offsetParent,
          o = s(e),
          i = s(t),
          e = n(e),
          t = n(t);
      return o.top += parseInt(e.borderTopWidth) || 0, o.left += parseInt(e.borderLeftWidth) || 0, {
        top: i.top - o.top - (parseInt(t.marginTop) || 0),
        left: i.left - o.left - (parseInt(t.marginLeft) || 0)
      };
    },
    getElement: function (t) {
      var e = null;
      return "string" == typeof t ? e = c.querySelector(t) : a.jQuery && t instanceof a.jQuery && t.length ? e = t[0] : t instanceof Element && (e = t), e;
    },
    getStyle: n,
    getCascadedStyle: function (t) {
      var e,
          o = t.cloneNode(!0);
      o.style.display = "none", Array.prototype.slice.call(o.querySelectorAll("input[type=\"radio\"]")).forEach(function (t) {
        t.removeAttribute("name");
      }), t.parentNode.insertBefore(o, t.nextSibling), o.currentStyle ? e = o.currentStyle : a.getComputedStyle && (e = c.defaultView.getComputedStyle(o, null));
      var i,
          n,
          s,
          r = {};

      for (i in e) !isNaN(i) || "string" != typeof e[i] && "number" != typeof e[i] || (r[i] = e[i]);

      if (Object.keys(r).length < 3) for (var l in r = {}, e) isNaN(l) || (r[e[l].replace(/-\w/g, function (t) {
        return t.toUpperCase().replace("-", "");
      })] = e.getPropertyValue(e[l]));
      return r.margin || "auto" !== r.marginLeft ? r.margin || r.marginLeft !== r.marginRight || r.marginLeft !== r.marginTop || r.marginLeft !== r.marginBottom || (r.margin = r.marginLeft) : r.margin = "auto", r.margin || "0px" !== r.marginLeft || "0px" !== r.marginRight || (s = (n = t.offsetLeft - t.parentNode.offsetLeft) - (parseInt(r.left) || 0) - (parseInt(r.right) || 0), 0 != (s = t.parentNode.offsetWidth - t.offsetWidth - n - (parseInt(r.right) || 0) + (parseInt(r.left) || 0) - s) && 1 != s || (r.margin = "auto")), o.parentNode.removeChild(o), o = null, r;
    }
  };
}(window);
export default exports;